<template>
  <v-app>
    <v-row align="center">
      <v-spacer />
      <v-col align="center">
        <v-img :src="logoUrl" max-width="240" max-height="240" align="center" />
      </v-col>
      <v-col cols="4" align="center">
        <v-card flat max-width="350">
          <v-card-title class="text-h2 mb-8">nobunaga</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-container>
                <v-text-field
                  prepend-icon="mdi-account-circle"
                  v-model="credentials.userId"
                  label="登録メールアドレス"
                  :rules="[rules.required, rules.email]"
                />

                <v-text-field
                  :type="showPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  v-model="credentials.password"
                  label="パスワード"
                  :rules="[rules.required, rules.password]"
                />
              </v-container>
            </v-form>

            <v-spacer />
            <v-row justify="center">
              <v-col>
                <v-btn
                  :disabled="!valid"
                  x-large
                  block
                  color="primary"
                  @click="login"
                  >アカウント作成</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- snack -->
    <v-snackbar v-model="snack" :timeout="snackTimeout" :color="snackColor">
      {{ snackText }}
    </v-snackbar>

    <!-- dialog -->
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h3">
          本人確認メールを送信しました
        </v-card-title>
        <v-card-text class="text-h5">
          この画面を閉じてメールをご確認ください。<br />もし届いていない場合、迷惑メールフォルダもご確認ください。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="primary" text @click="confirmOk"> とじる </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay v-show="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AuthCreate",
  data: () => ({
    logoUrl: require("@/assets/logo_nobunaga.png"),
    loginStatus: "",
    loading: false,
    showPassword: false,
    valid: true,
    credentials: {},
    rules: {
      required: (v) => !!v || "必須入力です",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "無効なメールアドレスです";
      },
      password: (v) =>
        (v && 6 <= v.length) || "パスワードは6文字以上にしてください",
    },

    // snack
    snack: false,
    snackTimeout: -1,
    snackColor: "",
    snackText: "",

    // dialog
    dialog: false,
  }),
  async mounted() {},
  computed: {
    ...mapGetters(["progress"]),
  },
  methods: {
    ...mapActions(["createFirebaseUser"]),
    async login() {
      this.snack = false;

      // 入力チェック
      const submit = this.$refs.form.validate();
      if (!submit) {
        return;
      }

      // 画面入力値
      const email = this.credentials.userId;
      const password = this.credentials.password;

      // アカウント作成
      const result = await this.createFirebaseUser({ email, password });
      this.credentials.password = null; // クリア (連打防止)
      if (result) {
        // 作成成功
        this.dialog = true;
      } else {
        // 作成失敗
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "アカウント作成に失敗しました。もう一度お願いします。";
      }
    },
    confirmOk() {
      this.$router.replace("/login");
    },
  },
};
</script>
